import React from "react";
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Styled from "@emotion/styled";
import { theme } from "../utils/global-styles/theme";
import { graphql } from "gatsby";

const NotFoundPage = ({ data: { PageNotFound } }) => {
  const { heading, shortDescription, contentModules } = PageNotFound;
  const Wrapper = Styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
  `,
    HomeLink = Styled(Link)`
      border-bottom-color: ${theme.colors.main};
      border-bottom-style: solid;
      border-bottom-width: 4px;
      padding-bottom: 2.5px;
      text-transform: uppercase;
    `,
    Heading = Styled("h1")`
      margin-top: 1em;
      margin-bottom: 0.3em;
    `,
    Description = Styled("h4")`
      margin-bottom: 4em;
    `;
  return (
      <Layout isDetailView={false}>
        <Wrapper>
          <Heading className="primary">{heading || "404"}</Heading>
          <Description className="secondary">
            {shortDescription || "Page not found"}
          </Description>
          <HomeLink
            className="primary primary-border secondary-hover secondary-border-hover"
            to={contentModules[0].url ? contentModules[0].url : "/"}
          >
            {contentModules[0].linkText || "GO BACK TO THE HOMEPAGE"}
          </HomeLink>
        </Wrapper>
      </Layout>
  );
};

export const query = graphql`
  query PageNotFound {
    PageNotFound: contentfulPageLayout(
      queryIdentifier: { eq: "PageNotFound" }
    ) {
      heading
      shortDescription
      contentModules {
        ... on ContentfulLink {
          linkText
          url
        }
      }
    }
  }
`;

export default NotFoundPage;
